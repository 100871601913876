var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "sm": "12"
    }
  }, [_c('b-card', [_c('b-form-group', {
    attrs: {
      "label": "Metode Pembayaran"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "options": [{
        value: 1,
        text: 'Tunai'
      }, {
        value: 2,
        text: 'Transfer'
      }, {
        value: 3,
        text: 'Giro'
      }, {
        value: 4,
        text: 'Nota Putih'
      }],
      "label": "text"
    },
    model: {
      value: _vm.payment.metode,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "metode", $$v);
      },
      expression: "payment.metode"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.payment.metode == 3 ? 'Nominal Giro' : 'Nominal Bayar'
    }
  }, [_vm.isNotaPutih || _vm.isSaldoBigger ? _c('b-form-input', {
    attrs: {
      "disabled": "",
      "autofocus": "",
      "type": "text",
      "value": _vm.payment.nominal
    }
  }) : _c('b-form-input', {
    attrs: {
      "autofocus": "",
      "type": "text"
    },
    on: {
      "keyup": _vm.doFormatRupiahNominal
    },
    model: {
      value: _vm.payment.nominal,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "nominal", $$v);
      },
      expression: "payment.nominal"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tanggal Transaksi"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.payment.tgl_pembayaran,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "tgl_pembayaran", $$v);
      },
      expression: "payment.tgl_pembayaran"
    }
  })], 1), _vm.payment.metode == 2 ? _c('b-form-group', {
    attrs: {
      "label": "Nama Bank Tujuan"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.payment.bank,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "bank", $$v);
      },
      expression: "payment.bank"
    }
  })], 1) : _vm._e(), _vm.payment.metode == 3 ? _c('b-form-group', {
    attrs: {
      "label": "No. Giro"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.payment.no_giro,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "no_giro", $$v);
      },
      expression: "payment.no_giro"
    }
  })], 1) : _vm._e(), _vm.payment.metode == 3 ? _c('b-form-group', {
    attrs: {
      "label": "Tanggal Giro"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.payment.tgl_giro,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "tgl_giro", $$v);
      },
      expression: "payment.tgl_giro"
    }
  })], 1) : _vm._e(), [3, 4].includes(_vm.payment.metode) ? _c('b-form-group', {
    attrs: {
      "label": "Tanggal Bayar"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.payment.tgl_bayar,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "tgl_bayar", $$v);
      },
      expression: "payment.tgl_bayar"
    }
  })], 1) : _vm._e(), [2, 3].includes(_vm.payment.metode) ? _c('b-form-group', {
    attrs: {
      "label": "No. Rekening"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.payment.norek,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "norek", $$v);
      },
      expression: "payment.norek"
    }
  })], 1) : _vm._e(), [2, 3].includes(_vm.payment.metode) ? _c('b-form-group', {
    attrs: {
      "label": "Pemilik"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.payment.pemilik,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "pemilik", $$v);
      },
      expression: "payment.pemilik"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Keterangan"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.payment.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.payment, "keterangan", $$v);
      },
      expression: "payment.keterangan"
    }
  })], 1)], 1), _vm.$route.params.id && !_vm.$route.meta.bayar ? _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Riwayat Pembayaran")])]), _vm.historyPembayaran.length > 0 ? _c('section', _vm._l(_vm.historyPembayaran, function (payment, i) {
    return _c('ul', {
      key: i,
      staticClass: "bg-light p-1 list-unstyled w-100 mb-1"
    }, [_vm.$route.meta.edit ? _c('li', {
      staticClass: "d-flex justify-content-end mb-2"
    }, [_c('feather-icon', {
      staticClass: "cursor-pointer text-danger",
      attrs: {
        "size": "24",
        "icon": "XIcon"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.removePembayaran({
            index: i,
            item: payment
          });
        }
      }
    })], 1) : _vm._e(), _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v(" Metode Pembayaran ")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(_vm.getMetode(payment.metode)))])])]), _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v(" Nominal Bayar ")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(_vm.getNominal(payment)))])])]), _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v(" Jumlah Bayar ")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(_vm.transaction.total_bayar))])])])]);
  }), 0) : _vm._e()]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Rincian"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('ul', {
    staticClass: "list-unstyled w-100"
  }, [_c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Metode ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.getMetode(_vm.payment.metode)))])])]), _vm.payment.metode == 2 ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Bank ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.payment.bank))])])]) : _vm._e(), _vm.payment.metode == 3 ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" No. Giro ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.payment.no_giro))])])]) : _vm._e(), _vm.payment.metode == 3 ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Tanggal Giro ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.payment.tgl_giro))])])]) : _vm._e(), _vm.payment.metode == 3 ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Tanggal Bayar ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.payment.tgl_bayar))])])]) : _vm._e(), _vm.payment.metode == 3 ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" No. Rekening ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.payment.norek))])])]) : _vm._e(), _vm.payment.metode == 3 ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Pemilik ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.payment.pemilik))])])]) : _vm._e(), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Sub Total ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.totalBayar)))])])]), _vm.$route.params.id && _vm.$route.meta.bayar ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Total Tagihan ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.formatRupiah(_vm.totalHutang)))])])]) : _vm._e(), _vm.payment.diskon && _vm.payment.diskon > 0 ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Diskon ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("-" + _vm._s(_vm.formatRupiah(_vm.payment.diskon)))])])]) : _vm._e(), _vm.currentKonsumen && _vm.isFirstPayment ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1 "
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Saldo Konsumen ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("-" + _vm._s(_vm.currentKonsumen && parseInt(_vm.currentKonsumen.saldo) < parseInt(_vm.totalBayar) ? _vm.formatRupiah(_vm.currentKonsumen.saldo) : _vm.formatRupiah(_vm.totalBayar)))])])]) : _vm._e(), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Total ")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', [_c('strong', [_vm._v(_vm._s(_vm.grandTotal < 0 ? 0 : _vm.formatRupiah(_vm.grandTotal)))])]), _vm.grandTotal < 0 ? _c('strong', {
    staticClass: "text-success"
  }, [_vm._v("/lunas")]) : _vm._e()])]), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Pembayaran ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.payment.nominal)))])])]), _c('li', {
    staticClass: "mt-3"
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.payment.nominal < 0 || _vm.isPaymentOver,
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }