var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "xl",
      "id": "barang-modal",
      "no-close-on-backdrop": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.chooseBarang($event);
            }
          }
        }, [_vm._v("Pilih Barang")])];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "small": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.barangs,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function callback($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.stok > 0 ? _c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedBarangs,
            callback: function callback($$v) {
              _vm.selectedBarangs = $$v;
            },
            expression: "selectedBarangs"
          }
        }) : _c('feather-icon', {
          staticClass: "text-damger",
          attrs: {
            "icon": "SlashIcon"
          }
        })];
      }
    }, {
      key: "cell(harga)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga)) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function fn(_ref3) {
        var item = _ref3.item,
          index = _ref3.index;
        return [item.stok > 0 ? _c('b-form-input', {
          staticClass: "w-75",
          attrs: {
            "type": "number"
          },
          on: {
            "keyup": function keyup($event) {
              return _vm.onSatuanKonversiSelected(index);
            }
          },
          model: {
            value: item.qty,
            callback: function callback($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        }) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Stok kosong")])];
      }
    }, {
      key: "cell(stok)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.stok) + " " + _vm._s(item.satuan ? item.satuan.satuan : '') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref5) {
        var item = _ref5.item,
          index = _ref5.index;
        return [item.konversi && item.konversi.length > 0 ? _c('b-form-select', {
          attrs: {
            "options": item.konversi
          },
          on: {
            "change": function change($event) {
              return _vm.onSatuanKonversiSelected(index);
            }
          },
          model: {
            value: item.selectedKonversi,
            callback: function callback($$v) {
              _vm.$set(item, "selectedKonversi", $$v);
            },
            expression: "item.selectedKonversi"
          }
        }) : _c('span', [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")])];
      }
    }, {
      key: "cell(converted)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.konversi && item.konversi.length > 0 ? _c('strong', [_vm._v(" " + _vm._s(item.converted) + " "), item.converted > 0 ? _c('span', [_vm._v(_vm._s(item.satuan ? item.satuan.satuan.toLowerCase() : ''))]) : _vm._e()]) : _c('i', {
          staticClass: "text-danger"
        }, [_c('small', [_vm._v("Satuan Konversi belum diinput")])])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }